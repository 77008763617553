import "./NewsCRUD.css";
import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Stack, Button } from "@mui/material";

import FileBase64 from "react-file-base64";
import DeleteIcon from "@mui/icons-material/Delete";

export default function NewsCRUD() {
  const lang = "en";
  let { id } = useParams();
  const [data, setData] = useState();
  const token = localStorage.getItem("token");

  const [titleEn, setTitleEn] = useState("");
  const [titleAr, setTitleAr] = useState("");
  const [bodyEn, setBodyEn] = useState("");
  const [bodyAr, setBodyAr] = useState("");
  const [media, setMedia] = useState([]);

  function getFiles(files) {
    console.log(files);
    files.map((file) => {
      if (file.type.includes("image") || file.type.includes("video")) {
        // todo: check file size
        // console.log(file.base64);
        setMedia([...media, file.base64]);
      } else {
        alert("Please select an image or video");
      }
      return file.base64;
    });
    console.log(media);
  }

  function updateRecord() {
    console.log("updating record");
    let newData = {
      title: {
        en: titleEn,
        ar: titleAr,
      },
      body: {
        en: bodyEn,
        ar: bodyAr,
      },
      media: media,
      date: data.date,
    };
    fetch(`https://omegacare-server.testing.ahmedkira.com/news/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newData),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        alert("Record updated successfully");
      })
      .catch((err) => console.log(err));
  }
  function deleteRecord() {
    fetch(`https://omegacare-server.testing.ahmedkira.com/news/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        alert("Record deleted successfully");
      })
      .catch((err) => console.log(err));
  }
  function createRecord() {
    let newData = {
      title: {
        en: titleEn,
        ar: titleAr,
      },
      body: {
        en: bodyEn,
        ar: bodyAr,
      },
      media: media,
      date: data.date,
    };
    fetch(`https://omegacare-server.testing.ahmedkira.com/news`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newData),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        alert("Record created successfully");
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    if (id && id !== "new") {
      fetch(`https://omegacare-server.testing.ahmedkira.com/news/${id}`)
        .then((res) => res.json())
        .then((res) => {
          setData(res);
          console.log(res);
          setTitleEn(res.title.en);
          setTitleAr(res.title.ar);
          setBodyEn(res.body.en);
          setBodyAr(res.body.ar);
          setMedia(res.media);
        });
    } else if (id === "new") {
      setData({
        title: {
          en: "",
          ar: "",
        },
        body: {
          en: "",
          ar: "",
        },
        media: [],
        date: new Date().toISOString(),
      });
    } else {
      // no id provided
    }
  }, []);

  return (
    <div className="news-crud">
      <h1 style={{ paddingBottom: "10px" }}>News CRUD</h1>
      {/* <span>Editing: {id}</span> */}
      {data && (
        <div className="data">
          <Stack
            direction="column"
            spacing={2}
            className="data-input-container"
          >
            <Stack
              direction="column"
              spacing={2}
              justifyContent="space-between"
            >
              <label>Title in English</label>
              <input
                type="text"
                placeholder="Title in English"
                defaultValue={data.title["en"]}
                className="title"
                onChange={(e) => setTitleEn(e.target.value)}
              />
              <label>Title in Arabic</label>
              <input
                dir="rtl"
                type="text"
                placeholder="العنوان باللغة العربية"
                defaultValue={data.title["ar"]}
                className="title"
                onChange={(e) => setTitleAr(e.target.value)}
              />
              <label>Date</label>
              <input
                type="date"
                placeholder="Date"
                value={data.date.slice(0, 10)}
                className="data"
                readOnly
                style={{ width: "465px" }}
              />
            </Stack>
            <label>Body in English</label>
            <textarea
              placeholder="Body in English"
              defaultValue={data.body["en"]}
              className="body"
              onChange={(e) => setBodyEn(e.target.value)}
            />
            <label>Body in Arabic</label>
            <textarea
              placeholder="المحتوى باللغة العربية"
              dir="rtl"
              defaultValue={data.body["ar"]}
              className="body"
              onChange={(e) => setBodyAr(e.target.value)}
            />
            <Stack direction="column" spacing={2}>
              <span>Media</span>
              <Stack direction="row" spacing={2}>
                {media.map((item, index) => (
                  <div key={index} className="media-item">
                    <img src={item} alt="media" />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        // alert do you want to delete this media? on click yes, update media
                        if (
                          window.confirm(
                            "Are you sure you want to delete this media?"
                          )
                        ) {
                          let newMedia = [...media];
                          newMedia.splice(index, 1);
                          setMedia(newMedia); // need await
                          // console.log(media.length);
                          // updateRecord();
                        } else {
                          console.log("cancelled");
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                ))}
                <div className="media-item">
                  <FileBase64 multiple={true} onDone={getFiles} />
                  {/* <input type="file" onChange={(e) => {
                                        let newMedia = [...media];
                                        newMedia.push(e.target.files[0]);
                                        setMedia(newMedia);
                                    }} /> */}
                </div>
              </Stack>
            </Stack>
          </Stack>
        </div>
      )}
      <Stack direction="row" justifyContent="space-evenly" className="buttons">
        <Button variant="contained" color="primary">
          <Link className="white" to="/medical-network">
            Back
          </Link>
        </Button>
        {id !== "new" && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              updateRecord();
            }}
          >
            Update
          </Button>
        )}
        <Button
          type=""
          variant="contained"
          color="primary"
          onClick={() => {
            window.confirm("Are you sure you want to delete this post?");
            deleteRecord();
          }}
          style={{
            backgroundColor: "#cc0000",
          }}
          startIcon={<DeleteIcon />}
        >
          Delete Post
        </Button>
        {id === "new" && (
          <Button variant="contained" color="primary">
            <Link
              className="white"
              to="/medical-network/create"
              onClick={() => {
                createRecord();
              }}
            >
              Create
            </Link>
          </Button>
        )}
      </Stack>
    </div>
  );
}
