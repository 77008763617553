import React, { useEffect, useState } from "react";
import "./MNTable.css";
// import Loading from "./Loading";
import SearchBar from "./SearchBar";
import MNTableFilters from "./MNTableFilters";
// import LanguageContext from "../contexts/LanguageContext";
import {Pagination, Button, Stack} from "@mui/material";
import {Link} from "react-router-dom";

export default function MNTable() {
    const lang = "en";
  const PAGE_SIZE = 10;
  const urlOptionsPrefix = `limit=${PAGE_SIZE}`;
  const [urlOptions, setUrl] = useState(`offset=0&&`);
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [orderBy, setOrderBy] = useState("governorate");
  const [sortDir, setSortDir] = useState(1);
  const [filtersString, setFiltersString] = useState("");
  const [queryString, setQueryString] = useState("");
  const [distincts, setDistincts] = useState();
  const [rawData, setRawData] = useState();

  useEffect(() => {
    const url = `https://omegacare-server.testing.ahmedkira.com/medical-network?${urlOptionsPrefix}&${urlOptions}`;
    console.log("fetching::: ", url);
    (async () => {
      try {
        const response = await (await fetch(url)).json();
        setRawData(response);
        setData(response.data);
        setDataCount(response.count);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [urlOptions]);
  useEffect(() => {
    const url = `https://omegacare-server.testing.ahmedkira.com/get-all-distinct`;
    console.log("fetching::: ", url);
    (async () => {
      try {
        const response = await (await fetch(url)).json();
        setDistincts(response);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  const reset = () => {
    setFiltersString("");
    setQueryString("");
  };

  useEffect(() => {
    setUrl(`offset=0&${filtersString}&${queryString}`);
    console.log("query: " + urlOptions);
  }, [filtersString, queryString]);

  useEffect(() => {
    setUrl(`offset=${PAGE_SIZE * pageNo}&${filtersString}&${queryString}`);
    console.log("rendering page: " + pageNo);
  }, [pageNo]);

  useEffect(() => {
    data &&
      setData( data.sort((a, b) => a[orderBy] > b[orderBy] ? sortDir : -sortDir));
  }, [data, orderBy, sortDir]); // pageNo

  // if(loading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div className="MNTable" >
      <div className="MNTable-header" >
        {/* {distinctsLoading && (
          <Loading
            text={
              lang === "en"
                ? "Loading medical network"
                : "جاري تحميل الشبكة الطبية"
            }
          />
        )} */}
        {distincts && (
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <MNTableFilters
              distincts={distincts}
              setFiltersString={setFiltersString}
            />
            <SearchBar
              setQueryString={setQueryString}
              onChange={() => {
                queryString.replace(
                  /[^a-zA-Z0-9\u0621-\u064A\u0660-\u0669 @#%*]/g,
                  ""
                );
              }}
            />
            <Button variant="contained" disableElevation onClick={reset}>
              {lang === "en" ? "Reset" : "إعادة للأصل"}
            </Button>
          </Stack>
        )}
      </div>
      {/* {loading && (
        <Loading
          text={
            lang === "en"
              ? "Loading medical network"
              : "جاري تحميل الشبكة الطبية"
          }
        />
      )} */}
      {/* {error && <p>Error: {error} </p>} */}
      {data && data.length !== 0 && (
        <div className="table-container" >
          <table
            style={{
              direction: lang === "en" ? "lrt" : "rtl",
            }}
          >
            <thead>
              {/* add on click to sory by clicked category */}
              <tr>
                {/* <th>{"id"}</th> */}
                <th
                  onClick={() => {
                    setOrderBy("governorate");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Governorate" : "المحافظة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("region");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Region" : "المنطقة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("service_provider");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Service Provider" : "موفر لخدمة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("branch_type");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Branch Type" : "نوع الفرع"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("service_type");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Service Type" : "نوع الخدمة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("network_type");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Network Type" : "نوع الشبكة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("specialty");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Specialty" : "التخصص"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("level");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Level" : "المستوى"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("address");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Address" : "العنوان"}
                </th>
                {/* <th>Email</th> */}
                <th
                  onClick={() => {
                    setOrderBy("phone");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Phone" : "ارقام التيليفون"}
                </th>
                <th>{"Edit"}</th>
                {/* <th>Phone 2</th> */}
                {/* <th>Notes</th> */}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item._id}>
                    {/* <td>{item._id}</td> */}
                    <td>{item.governorate}</td>
                    <td>{item.region}</td>
                    <td>{item.service_provider}</td>
                    <td>{item.branch_type}</td>
                    <td>{item.service_type}</td>
                    <td>{item.network_type}</td>
                    <td>{item.specialty}</td>
                    <td>{item.level}</td>
                    <td>{item.address}</td>
                    {/* <td>{item.email}</td> */}
                    <td>{item.phone}</td>
                    {/* <td>{item.phone2}</td> */}
                    {/* <td>{item.notes}</td> */}
                    <td> <Button variant="contained" disableElevation> <Link to={`/medical-network/edit/${item._id}`}>{lang === "en" ? "Edit" : "تعديل"}</Link></Button></td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* add data navgation usting  */}
          <div
            className="pagination"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          ></div>
        </div>
      )}
      {(!data || data.length === 0) && (
        <p>{lang === "en" ? "No results available." : "لا نتائج متاحة."}</p>
      )}
      <div className="lower-table-section">
        <Pagination
          size="small"
          count={Math.ceil(dataCount / PAGE_SIZE)}
          onChange={(e, value) => {
            setPageNo(value - 1);
          }}
          variant="outlined"
        />
      </div>
    </div>
  );
}
