import "./App.css";
import {
  // Link,
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import SideNav from "./components/SideNav";
import Header from "./components/Header";
import { Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "./contexts/UserContext";

import Login from "./routes/Login";
import Dashboard from "./routes/Dashboard";
import News from "./routes/News";
import MedicalNetwork from "./routes/MedicalNetwork";
import MedicalNetworkCRUD from "./routes/MedicalNetworkCRUD";
import NewsCRUD from "./routes/NewsCRUD";
import {checkLogin} from "./utils/checkLogin";

export default function App() {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  //check login
  useEffect(() => {
    checkLogin()?.then((user) => {
      console.log(user);
      if (user) {
        setUser(user);
        setIsLoggedIn(true);
      }
    });
  }, []);

  return (
    <Router>
      <UserContext.Provider
        value={{ user, setUser, isLoggedIn, setIsLoggedIn }}
      >
        {isLoggedIn ? (
          <Stack direction="row">
            <SideNav />
            <Stack direction="column">
              <Header />
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                {user.allowed_routes.includes("news") && (
                  <>
                    <Route path="/news" element={<News />} />
                    <Route path="/News/edit/:id" element={<NewsCRUD />} />
                  </>
                )}
                {user.allowed_routes.includes("medical-network") && (
                  <>
                    <Route
                      path="/medical-network"
                      element={<MedicalNetwork />}
                    />
                    <Route
                      path="/medical-network/edit/:id"
                      element={<MedicalNetworkCRUD />}
                    />
                  </>
                )}
                <Route
                  path="*"
                  element={<Navigate replace to="/dashboard" />}
                />
              </Routes>
            </Stack>
          </Stack>
        ) : (
          <Login />
        )}
      </UserContext.Provider>
    </Router>
  );
}
