import "./Login.css";
import React, { useState } from "react";
import { Stack, Button } from "@mui/material";

export default function Login() {
  // const { setUser, setIsLoggedIn } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [requested, setRequested] = useState(false);

  function handleSubmit(event) {
    let urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get("token");
    event.preventDefault();
    console.log(email, token);
    console.log("no token found request new token");
    fetch("https://omegacare-server.testing.ahmedkira.com/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setRequested(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className="login">
      {!requested ? (
        <form onSubmit={handleSubmit}>
          <Stack
            className="login-container"
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            <img src="/logo.svg" alt="logo" />
            <p style={{ color: "white", textAlign: "center" }}>
              Welcome to OmegaCare's Website Control Panel
            </p>
            <input
              type="email"
              required
              placeholder="Email"
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button variant="contained" type="submit">
              Login
            </Button>
          </Stack>
        </form>
      ) : (
        <Stack
          className="login-container"
          direction="column"
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          <h3>Login requested</h3>
          <p>Please check your email for a verification link</p>
        </Stack>
      )}
    </div>
  );
}
