const verifyToken = (_token) =>
  fetch("https://omegacare-server.testing.ahmedkira.com/account", {
    // fetch('https://omegacare-server.testing.ahmedkira.com/account', {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${_token}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data.auth) {
        localStorage.setItem("user", data);
        localStorage.setItem("token", _token);
        return data;
      }
    })
    .catch((err) => {
      console.log(err);
      return false;
    });

export const checkLogin = () => {
  let urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get("token");
  if (!token) token = localStorage.getItem("token");
  if (token && token.length > 0) return verifyToken(token);
};
