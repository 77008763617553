import { Stack } from '@mui/material';
import './Overview.css';
import CountUp from 'react-countup';
import React,{
    useState,
    useEffect
} from 'react';
 
function OverviewItem({title, counter}){
    return (
        <div className="overview-item">
            <h2>{title}</h2>
            <CountUp start={0} end={counter} duration={2} />
        </div>
    );
}
export default function Overview() {
    const [counter, setCounter] = useState();
    useEffect(() => {
        fetch(`https://omegacare-server.testing.ahmedkira.com/get-all-distinct`).then(res => res.json()).then(res => {
            setCounter({
                news: res.newsCount,
                medicalNetwork: res.medicalNetworkCount,
            })
        })
    }, [])
    return (
        <div className="overview">
            <h1>Overview</h1>
            { counter && <Stack direction='row' spacing={2} p={2} justifyContent='space-evenly' className='container'>
                <OverviewItem title='News' counter={counter.news} />
                <OverviewItem title='Network' counter={counter.medicalNetwork} />
            </Stack> }
        </div>
    );
}
