import "./SideNav.css";
import { Link } from "react-router-dom";
import { Stack, Button } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

// links news crud , network crud, profile , dashboard

// function NavLink({ to, children, ...props }) {
//   return (
//     <Link to={to} {...props}>
//       {children}
//     </Link>
//   );
// }

export default function SideNav() {
  const { user } = useContext(UserContext);
  return (
    <div className="sidenav">
      <Stack
        direction="column"
        mt={4}
        spacing={1}
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          className="pfp"
          src="https://www.w3schools.com/howto/img_avatar.png"
          alt="profile"
        />
        <h3>Hello, {user.user}</h3>

        <Link to="/dashboard" className="nav-link">
          Dashboard
        </Link>
        {user.allowed_routes.includes("news") && (
          <Link to="/news" className="nav-link">
            News
          </Link>
        )}
        {user.allowed_routes.includes("medical-network") && (
          <Link to="/medical-network" className="nav-link">
            Medical Network
          </Link>
        )}
        {/* <Link to="/profile">Profile</Link> */}
        <Button
          variant="contained"
          style={{ position: "absolute", bottom: 20 }}
          onClick={() => {
            localStorage.clear();
            window.location = "/";
          }}
        >
          Logout
        </Button>
      </Stack>
    </div>
  );
}
