import './MedicalNetwork.css';
import MNTable from './../components/MNTable';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

export default function MedicalNetwork() {

    return (
        <div className="medical-network">
            <Stack direction="row" spacing={2} justifyContent="space-between">
                <h1 style={{marginBottom: "0.5em"}}>Medical Network</h1>
                <Link to="/medical-network/edit/new" className="link">Add New Medical Center</Link>
            </Stack>
            <MNTable />
        </div>
    );
}