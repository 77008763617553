import "./MedicalNetworkCRUD.css";
import { useParams, Link } from "react-router-dom";
import { Stack, Button } from "@mui/material";
import React, { useState, useEffect } from "react";

export default function MedicalNetworkCRUD() {
  let { id } = useParams();
  const [distincts, setDistincts] = useState(null);
  const token = localStorage.getItem("token");

  const [data, setData] = useState();

  const [governorate, setGovernorate] = useState("");
  const [region, setRegion] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [branchType, setBranchType] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [networkType, setNetworkType] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [level, setLevel] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState(true);

  function updateRecord() {
    const url = `https://omegacare-server.testing.ahmedkira.com/medical-network/${id}`;
    // const url = `https://omegacare-server.testing.ahmedkira.com/medical-network/${id}`;
    let newData = {};
    if (governorate) newData.governorate = governorate;
    if (region) newData.region = region;
    if (serviceProvider) newData.service_provider = serviceProvider;
    if (branchType) newData.branch_type = branchType;
    if (serviceType) newData.service_type = serviceType;
    if (networkType) newData.network_type = networkType;
    if (specialty) newData.specialty = specialty;
    if (level) newData.level = level;
    if (address) newData.address = address;
    if (phone) newData.phone = phone;
    if (status !== undefined) newData.status = status ? 1 : 0;
    console.log("newData", newData);

    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ data: newData }),
    };
    fetch(url, options)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        alert("Record updated successfully");
      })
      .catch((err) => console.log(err));
  }
  function deleteRecord() {
    const url = `https://omegacare-server.testing.ahmedkira.com/medical-network/${id}`;
    // const url = `https://omegacare-server.testing.ahmedkira.com/medical-network/${id}`;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(url, options)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        alert("Record deleted successfully");
      })
      .catch((err) => console.log(err));
  }
  function createRecord() {
    const url = `https://omegacare-server.testing.ahmedkira.com/medical-network`;
    // const url = `https://omegacare-server.testing.ahmedkira.com/medical-network`;
    let newData = {};
    if (governorate) newData.governorate = governorate;
    if (region) newData.region = region;
    if (serviceProvider) newData.service_provider = serviceProvider;
    if (branchType) newData.branch_type = branchType;
    if (serviceType) newData.service_type = serviceType;
    if (networkType) newData.network_type = networkType;
    if (specialty) newData.specialty = specialty;
    if (level) newData.level = level;
    if (address) newData.address = address;
    if (phone) newData.phone = phone;
    if (status !== undefined) newData.status = status ? 1 : 0;
    console.log("newData", newData);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ data: newData }),
    };
    fetch(url, options)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        alert("Record created successfully");
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (id !== "new") {
      (async () => {
        const response = await (
          await fetch(
            `https://omegacare-server.testing.ahmedkira.com/medical-network/${id}`
          )
        ).json();
        setData(response.data);
        console.log(response);
        setGovernorate(response.data.governorate);
        setRegion(response.data.region);
        setServiceProvider(response.data.service_provider);
        setBranchType(response.data.branch_type);
        setServiceType(response.data.service_type);
        setNetworkType(response.data.network_type);
        setSpecialty(response.data.specialty);
        setLevel(response.data.level);
        setAddress(response.data.address);
        setPhone(response.data.phone);
        setStatus(response.data.status ? true : false);
      })();
    }
  }, []);
  useEffect(() => {
    fetch(`https://omegacare-server.testing.ahmedkira.com/get-all-distinct`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setDistincts(res);
      });
  }, []);

  return (
    <div className="medical-network-crud">
      <h1>Medical Network CRUD</h1>
      <div className="container">
        {id === "new" && <h2>Create New Medical Network</h2>}
        {id !== "new" && <p>Editing: {id}</p>}
        {(data || id === "new") && distincts && (
          <div className="form">
            <Stack spacing={2}>
              <div className="form-group">
                <label>Service Provider: </label>
                <input
                  type="text"
                  className="form-item"
                  id="service_provider"
                  placeholder="Service Provider"
                  defaultValue={serviceProvider}
                  onChange={(e) => setServiceProvider(e.target.value)}
                />
              </div>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                className="form-group"
              >
                <label>Service Type: </label>
                <select
                  className="form-item"
                  id="service_type"
                  defaultValue={serviceType}
                  onChange={(e) => setServiceType(e.target.value)}
                >
                  <option value="">Select Service Type</option>
                  {distincts.serviceTypes.map((service) => (
                    <option key={service} value={service}>
                      {service}
                    </option>
                  ))}
                </select>
                <label> Specialty: </label>
                <select
                  className="form-item"
                  id="specialty"
                  defaultValue={specialty}
                  onChange={(e) => setSpecialty(e.target.value)}
                >
                  <option value="">Select Specialty</option>
                  {distincts.specialties.map((specialty) => (
                    <option key={specialty} value={specialty}>
                      {specialty}
                    </option>
                  ))}
                </select>
              </Stack>
              <Stack direction="row" spacing={2} className="form-group">
                <div className="input-group">
                  <label>Governorate: </label>
                  <select
                    className="form-item"
                    id="governorate"
                    defaultValue={governorate}
                    onChange={(e) => setGovernorate(e.target.value)}
                  >
                    <option value="">Select Governorate</option>
                    {distincts.governorates.map((governorate) => (
                      <option key={governorate.name} value={governorate.name}>
                        {governorate.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-group">
                  <label>Region: </label>
                  <select
                    disabled={!governorate}
                    className="form-item"
                    id="region"
                    defaultValue={region}
                    onChange={(e) => setRegion(e.target.region)}
                  >
                    <option value="">Select Region</option>
                    {governorate &&
                      distincts.governorates
                        .find((e) => e.name === governorate)
                        .regions.map((region, i) => (
                          <option key={i} value={region}>
                            {region}
                          </option>
                        ))}
                  </select>
                </div>
              </Stack>
              <Stack direction="row" spacing={2} className="form-group">
                <div className="input-group">
                  <label>Branch Type: </label>
                  <select
                    className="form-item"
                    id="branch_type"
                    defaultValue={branchType}
                    onChange={(e) => setBranchType(e.target.value)}
                  >
                    <option value="">Select Branch Type</option>
                    {distincts.branchTypes.map((branch) => (
                      <option key={branch} value={branch}>
                        {branch}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-group">
                  <label>Phone Number: </label>
                  <input
                    type="text"
                    className="form-item"
                    id="phone"
                    placeholder="Phone"
                    defaultValue={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </div>
              </Stack>
              <div className="form-group">
                <label>Network Type: </label>
                <input
                  type="text"
                  className="form-item"
                  id="network_type"
                  placeholder="Network Type"
                  defaultValue={networkType}
                  onChange={(e) => {
                    setNetworkType(e.target.value);
                  }}
                />
                <label>Level: </label>
                <select
                  className="form-item"
                  id="level"
                  defaultValue={level}
                  onChange={(e) => setLevel(e.target.value)}
                >
                  <option value="">Select Level</option>
                  {distincts.levels.map((level) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Address: </label>
                <input
                  type="text"
                  className="form-item"
                  id="address"
                  placeholder="Address"
                  defaultValue={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Status: </label>
                <input
                  type="checkbox"
                  className="form-item"
                  id="status"
                  placeholder="Status"
                  defaultChecked={status}
                  onChange={(e) => {
                    setStatus(e.target.checked);
                  }}
                />
              </div>
            </Stack>
          </div>
        )}
      </div>

      <Stack direction="row" justifyContent="space-evenly" className="buttons">
        <Button variant="contained" color="primary">
          <Link className="white" to="/medical-network">
            Back
          </Link>
        </Button>
        { id!=="new" && (<>
            <Button
          variant="contained"
          color="primary"
          onClick={() => {
            console.log("clicked");
            updateRecord();
          }}
        >
          Update
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            deleteRecord();
          }}
          style={{
            backgroundColor: "red",
          }}
        >
          Delete
        </Button>
      </>) }
        {id === "new" && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              createRecord();
            }}
          >
            Create
          </Button>
        )}
      </Stack>
    </div>
  );
}
