import './News.css';
import React, {
    useState,
    useEffect
} from 'react';
import {
    Stack,
} from '@mui/material';
import {Link} from 'react-router-dom';

function Post({data, lang}){
    return(
        <div className="post">
            <Stack direction="row" spacing={2}>
            <img src={data.media[0]} alt="post"/>
            <div className="content">
                <Stack direction="row" justifyContent="space-between">
                <h3>{data.title[lang]}</h3>
                <span>{data.date}</span>
                </Stack>
                <p>{((data.body[lang].length)<105)?data.body[lang]:data.body[lang].slice(0,105)+"..."}</p>
            </div>
            </Stack>
                <Link to={`/news/edit/${data._id}`} className="link">Read More / Edit</Link>
        </div>
    );
}

export default function News() {
    const lang = "en";

    const [data, setData] = useState();
    useEffect(() => {
        fetch('https://omegacare-server.testing.ahmedkira.com/news').then(res => res.json()).then(res => {
            setData(res.data);
            console.log(res);
        });
    }, []);
    return (
        <div className="news">
            <Stack direction="row" spacing={2} justifyContent="space-between">
                <h1 style={{paddingBottom: "10px"}}>News</h1>
                <Link to="/news/edit/new" className="link">Add New Post</Link>
            </Stack>
            <div className="news-list">
                <Stack direction="column" spacing={2}>
                    {data && data.map(post => <Post data={post} lang={lang} key={post._id}/>)}
                </Stack>


            </div>

        </div>
    );
}