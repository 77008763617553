import './Dashboard.css';
import Overview from './../components/Overview';

export default function Dashboard() {
    return (
        <div className="dashboard">
            <Overview />
        </div>
    );
}
